import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import Menu from "../components/Menu";

class Navbar extends Component {
  constructor(props) {
    super(props);

    const domain = window.location.hostname;
    const isProduct = domain.includes('afi');
    this.state = {
      demo: !isProduct
    };
  }

  componentDidMount() {
    if (this.state.demo) {
        document.title = "DEMO";
    }
  }

  render() {
    return (
      <nav className="tw-flex tw-justify-between tw-items-center tw-bg-white">
        <Link to="/" className="tw-pl-6 tw-my-2">
          <img src="/logo.png" height="60" alt="logo"/>
        </Link>

        <div className="tw-py-6"><Menu/></div>

        <div className="tw-flex tw-justify-between tw-items-center">
          <div className="tw-pl-6 tw-py-2">
            <div className="tw-mb-2 tw-text-sm">{this.props.app.user.email}</div>
            <span className="tw-text-xs tw-capitalize tw-rounded-full text-pink border-pink tw-border tw-border-solid bg-pink-light tw-px-3 tw-py-1">
              {this.props.app.user.role === "super" ? "Super Admin" : this.props.app.user.role}
            </span>
          </div>

          <div className="tw-mx-3">
            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link to="/profile" className="dropdown-item">Profile</Link>
              <div className="dropdown-divider"></div>
              <Link to="/logout" className="tw-flex tw-justify-between tw-items-center dropdown-item"><span>Logout</span><FontAwesomeIcon icon={faSignOutAlt} className="tw-text-grey-light" /></Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  const { app } = state;
  return { app };
}

export default connect(mapStateToProps, {})(Navbar);
