import React, { Component } from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { Alert } from "react-bootstrap";
import * as api from "../../global/api";

const form = withFormik({
  mapPropsToValues: ({user, userRole, currentUser, workareaOptions}) => {
    const newUser = user || {};
    const newProps = {
      name: newUser.name || "",
      email: newUser.email || "",
      roleId: user ? user.role : "admin",
      tokenApi: newUser.tokenApi || "",
      defaultPassword: true
    };

    return newProps;
  },

  validate: (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Required";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.roleId) {
      errors.roleId = "Required";
    }

    return errors;
  },

  handleSubmit: (values, { setSubmitting, setErrors, props: { demo, user, createUserSuccess, updateUserSuccess } }) => {
    values.production = !demo;
    if (user) {
      let updateUser = api.updateUser;
      updateUser(user.id, values).then(({response, error}) => {
        if (error) {
          setSubmitting(false);
          setErrors(error.error || { message: error });
        } else {
          updateUserSuccess(response);
        }
      });
    } else {
      let createUser = api.createUser;
      delete values.tokenApi;
      createUser(values).then(({response, error}) => {
        if (error) {
          setSubmitting(false);
          setErrors(error.error || { message: error });
        } else {
          createUserSuccess(response);
        }
      });
    }
  },
});

class UserForm extends Component {
  handleChangeRole = (e) => {
    const { setFieldValue } = this.props;
    setFieldValue("roleId", e.target.value);
  }

  render() {
    const {
      user,
      rolesOptions,
      errors,
      values,
      setErrors,
      isSubmitting,
      setFieldValue,
      handleSubmit,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        {errors.message &&
          <Alert variant="danger" dismissible onClose={() => setErrors({})}>
            {errors.message}
          </Alert>
        }

        <div className="form-group">
          <label className="label label-black">Full Name</label>
          <input className={errors.name ? "form-control input input-red": "form-control input"}
            onChange={(e) => setFieldValue("name", e.target.value)}
            value={values.name}
            />
        </div>

        <div className="form-group">
          <label className="label label-black">Email Address</label>
          <input className={errors.email ? "form-control input input-red": "form-control input"}
            onChange={(e) => setFieldValue("email", e.target.value)}
            value={values.email}
            />
        </div>

        <div className="form-group">
          <label className="label label-black">User Role</label>
          <select onChange={this.handleChangeRole} value={values.roleId} className="custom-select tw-text-xs">
            {rolesOptions.map((option) =>
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            )}
          </select>
        </div>

        {!user &&
          <div className="form-group">
            <div className="tw-flex tw-items-center">
              <input type="checkbox" className="tw-mr-2" id="default-password" checked={values.defaultPassword} onChange={() => setFieldValue("defaultPassword", !values.defaultPassword)} />
              <label className="form-check-label tw-text-xs" htmlFor="default-password">Use Default Password: <strong>123123123</strong></label>
            </div>
          </div>
        }

        {user &&
          <div className="form-group">
            <label className="label label-black">Token Api</label>
            <input className={errors.tokenApi ? "form-control input input-red": "form-control input"}
              onChange={(e) => setFieldValue("tokenApi", e.target.value)}
              value={values.tokenApi}
              />
          </div>
        }

        <button type="submit"
          className="btn btn-block btn-pink"
          disabled={isSubmitting}>{user ? "Update User" : "Create User"}</button>
      </form>
    );
  }
}

UserForm.propTypes = {
  user: PropTypes.object,
  createUserSuccess: PropTypes.func.isRequired,
  updateUserSuccess: PropTypes.func.isRequired,

  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setErrors: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default form(UserForm);
