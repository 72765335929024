import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {withRouter} from "react-router-dom";
import postmanSample from '../assets/postman_collection.json';

class Menu extends Component {
  render() {
    const defaultLink = "tw-whitespace-no-wrap tw-px-6 tw-text-sm tw-text-black nav-link tw-cursor-pointer hover:text-pink";
    const activeLink = "tw-whitespace-no-wrap tw-px-6 tw-text-sm nav-link active tw-cursor-pointer hover:text-pink";
    const path = this.props.location.pathname;
    const isAdmin = this.props.app.user.role === "admin";
    const tokenApi = this.props.app.user.tokenApi || '';

    const downloadPostmanFile = async () => {
      const fileName = "postman_collection";
      const json = JSON.stringify(postmanSample).replaceAll("access_token_value", tokenApi);
      const blob = new Blob([json],{type: 'application/json'});
      const href = await URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName + ".json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    return (
      <ul className="nav">
        {isAdmin &&
          <li className="nav-item">
            <Link to="/" className={path === '/' ? activeLink : defaultLink}>Dashboard</Link>
          </li>
        }

        {isAdmin &&
          <li className="nav-item">
            <Link to="/users" className={path.includes('users') ? activeLink : defaultLink}>Users</Link>
          </li>
        }

        <li className="nav-item">
          <Link to="/requests" className={path.includes('requests') ? activeLink : defaultLink}>Requests</Link>
        </li>

        <li className="nav-item">
          <a href="https://afi-labs.gitbook.io/afi-routing-engine/-LtOZ1a-Ritq-iWagVx-/" className={defaultLink}>Docs</a>
        </li>

        <li className="nav-item">
          <a onClick={downloadPostmanFile} className={defaultLink}>Postman</a>
        </li>
      </ul>
    );
  }
}

function mapStateToProps(state) {
  const { app } = state;
  return { app };
}

export default connect(mapStateToProps, {})(withRouter(Menu));
