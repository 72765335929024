import React, { Component } from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { Alert } from "react-bootstrap";
import * as api from "../../global/api";

const form = withFormik({
  mapPropsToValues: () => {
    const newProps = {
      password: "",
      confirmPassword: ""
    };
    return newProps;
  },

  validate: (values) => {
    const errors = {};
    if (!values.password || values.password.length < 6) {
      errors.password = "Required";
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Required";
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Required";
    }

    return errors;
  },

  handleSubmit: (values, { setSubmitting, setErrors, props: { token, resetPasswordSuccess } }) => {
    api.userResetPassword({token, password: values.password}).then(({response, error}) => {
      if (error) {
        setSubmitting(false);
        setErrors(error.error || { message: error });
      } else {
        resetPasswordSuccess(response);
      }
    });
  },
});

class ResetPasswordForm extends Component {
  render() {
    const {
      errors,
      values,
      setErrors,
      isSubmitting,
      setFieldValue,
      handleSubmit,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        {errors.message &&
          <Alert variant="danger" dismissible onClose={() => setErrors({})}>
            {errors.message}
          </Alert>
        }

        <div className="form-input-group">
          <label className="label label-black">Password</label>
          <input className={errors.password ? "form-control input input-red": "form-control input"}
            type="password"
            onChange={(e) => setFieldValue("password", e.target.value)}
            value={values.password}
            />
        </div>

        <div className="form-input-group">
          <label className="label label-black">Confirm Password</label>
          <input className={errors.confirmPassword ? "form-control input input-red": "form-control input"}
            type="password"
            onChange={(e) => setFieldValue("confirmPassword", e.target.value)}
            value={values.confirmPassword}
            />
        </div>

        <button type="submit"
          className="btn btn-block btn-pink"
          disabled={isSubmitting}>SUBMIT</button>
      </form>
    );
  }
}

ResetPasswordForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setErrors: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default form(ResetPasswordForm);
