import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import 'bootstrap/dist/css/bootstrap.css';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js';
import './css/tailwind.css';
import './css/styles.css';

import configFontAwesome from "./config/fontAwesome";
import configFonts from "./config/fonts";

import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from "./config/store";
import rootSaga from "./sagas";

configFonts();
configFontAwesome();

const store = configureStore();
store.runSaga(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <App history={history}/>
  </Provider>,
  document.getElementById('root')
)
serviceWorker.unregister();
