import * as actions from "../global/actions";

const ATTRS = ["token", "id", "name", "email", "role", "tokenApi"];

function addUserCache(user) {
  ATTRS.forEach((attr) => {
    if (user[attr]) localStorage.setItem(attr, user[attr]);
  });
}

function getUserCache() {
  const user = {};
  ATTRS.forEach((attr) => {
    user[attr] = localStorage[attr];
  });
  return user;
}

function delUserCache() {
  ATTRS.forEach((attr) => {
    localStorage.removeItem(attr);
  });
}

const initState = {
  isAuthenticated: !!(localStorage.token),
  user: getUserCache(),
  message: "",
  checkboxs: [],
};

export default function(state = initState, action = {}) {
  switch (action.type) {
    case actions.APP_INIT_CHECKBOXS:
      return {
        ...state,
        checkboxs: new Array(action.length) || [],
      };

    case actions.APP_CHECK_CHECKBOXS:
      for (let index = 0; index < state.checkboxs.length; ++index) {
        state.checkboxs[index] = action.checked;
      }
      return {...state};

    case actions.APP_TOGGLE_CHECKBOXS:
      const { checkboxs } = state;
      checkboxs[action.index] = !checkboxs[action.index];
      return {
        ...state,
        checkboxs
      };

    case actions.USER_LOGIN_SUCCESS:
      const data = action.response.data;
      addUserCache(data);
      return {
        ...state,
        isAuthenticated: true,
        user: data|| {},
        message: "",
      };

    case actions.USER_LOGIN_FAILURE:
      delUserCache();
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        message: action.error
      };

    case actions.USER_LOGOUT_SUCCESS:
      delUserCache();
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        message: "",
      };

    default:
      return state;
  }
}
