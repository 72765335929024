import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import smartSearch from "smart-search";

import { Modal } from "react-bootstrap";
import UserForm from "../components/Forms/UserForm";
import * as api from "../global/api";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadUsersPage } from "../actions/users";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

import Layout from "../components/Layout";
import Pagination from "../components/Pagination";
import SearchInput from "../components/SearchInput";

const Body = styled.div`
`;

const rolesOptions = [
  { value: "admin", text: "Admin" },
  { value: "user", text: "User" },
];

const sortByOptions = [
  { value: "createdAt", text: "Created At" },
  { value: "name", text: "Full Name" },
  { value: "email", text: "Email" },
];

const searchItems = (items, filters) => {
  const search = _.lowerCase(filters.search);

  let users = items;

  if (search) {
    let tmp = smartSearch(users, [filters.search], { name: true, email: true }, { caseSensitive: true });
    tmp = tmp.map((i) => i.entry);
    users = tmp;
  }

  users = _.orderBy(users, [filters.orderBy], ['desc']);

  return users;
}

const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

class UsersPage extends Component {
  constructor(props) {
    super(props);

    const domain = window.location.hostname;
    const isProduct = domain.includes('afi');
    this.state = {
      items: [],
      filters: this.props.users.filters,
      showUserCreate: false,
      user: null,
      demo: !isProduct
    };
  }

  componentDidMount() {
    const { filters } = this.state;
    this.props.loadUsersPage(filters);
    this.reloadPage();
  }

  reloadPage = () => {
    this.loadMoreData(1, 1000);
  }

  loadMoreData = (page, limit) => {
    const filters = { page, limit };
    api.fetchUsers(filters).then(({response, error}) => {
      if (!error && response.data.length) {
        // Store data
        const items = _.unionBy(this.state.items, response.data, "id");
        // Load more
        this.setState({ items }, () => {
          this.loadMoreData(page+1, limit);
        });
      }
    });
  }

  handleMovePage = data => {
    const page = data.selected + 1;
    const filters = {
      ...this.state.filters,
      page
    };
    this.setState({filters});
  };

  onChangeSearch = (value) => {
    const filters = {
      ...this.state.filters,
      search: value,
      page: 1,
    };
    this.setState({filters});
  }

  onSort = (event) => {
    const filters = {
      ...this.state.filters,
      orderBy: event.target.value,
      page: 1,
    };
    this.setState({filters});
  }

  handleShowUserCreate = (user) => {
    this.setState({ showUserCreate: true, user });
  }

  handleCloseUserCreate = () => {
    this.setState({ showUserCreate: false });
  }

  handleCreateUser = () => {
    this.handleCloseUserCreate();
    this.reloadPage();
  };

  handleUpdateUser = (response) => {
    this.handleCloseUserCreate();
    const { items } = this.state;
    const itemIndex = _.findIndex(items, function(o) { return o.id === response.data.id; });
    items[itemIndex] = response.data;
    this.setState({ items });
  };

  handleDeleteUser = (id) => {
    api.deleteUser(id).then(({response, error}) => {
      if (error) {
        alert(error);
      } else {
        const { items } = this.state;
        const newItems = _.filter(items, function(o) { return o.id !== id; });
        this.setState({ items: newItems });
      }
    });
  }

  render() {
    const { items, filters } = this.state;
    const limit = filters.limit;
    const offset = limit * (filters.page - 1);
    const users = searchItems(items, filters);
    const pages = Math.ceil(users.length / limit);

    return (
      <Layout>
        <Body>
          <Modal show={this.state.showUserCreate} onHide={this.handleCloseUserCreate}>
            <Modal.Body>
              <div className="header">
                {this.state.user ? "Update User" : "New User"}
              </div>
              <div className="tw-text-xs">
                <UserForm
                  user={this.state.user}
                  rolesOptions={rolesOptions}
                  createUserSuccess={this.handleCreateUser}
                  updateUserSuccess={this.handleUpdateUser}
                  demo={this.state.demo}
                />
              </div>
            </Modal.Body>
          </Modal>

          <div className="tw-flex tw-justify-between tw-mb-4">
            <div className="tw-text-xl tw-font-medium">Users</div>
            <div onClick={() => this.handleShowUserCreate(null)} className="btn btn-sm btn-pink hover:tw-text-white">
              <FontAwesomeIcon icon="plus" className="tw-mr-2" /><span className="tw-text-xs">Add New User</span>
            </div>
          </div>

          <div className="tw-rounded tw-bg-white tw-shadow tw-mb-4">
            <div className="tw-p-4">
              <div className="tw-flex tw-flex-row tw-justify-between">
                <div className="tw-flex">
                  <SearchInput
                    placeholder="Search by Full Name, Email"
                    value={this.state.filters.search}
                    onChange={this.onChangeSearch}
                    />
                </div>

                <div className="tw-flex">
                  <div className="tw-whitespace-no-wrap tw-self-center tw-mr-2 tw-text-xs">Sort by</div>
                  <select onChange={this.onSort} className="custom-select tw-text-xs tw-py-1">
                    { sortByOptions.map((option) =>
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div>
              <div className="table-responsive tw-max-w-full">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary tw-text-white tw-text-sm">
                    <tr className="tw-uppercase">
                      <th className="tw-font-normal tw-whitespace-no-wrap">Full Name</th>
                      <th className="tw-font-normal">Email</th>
                      <th className="tw-font-normal">Role</th>
                      <th className="tw-font-normal">Total Call Api</th>
                      <th className="tw-font-normal tw-whitespace-no-wrap">Created Date</th>
                      <th className="tw-font-normal"></th>
                    </tr>
                  </thead>
                  <tbody className="tw-text-xs">
                    {users.slice(offset, offset + limit).map((item, index) => {
                      let role;
                      switch (item.role) {
                        case "admin":
                          role = <span className="tw-text-red">Admin</span>;
                          break;
                        case "super":
                          role = <span className="tw-text-blue">Super Admin</span>;
                          break;
                        default:
                          role = <span className="tw-text-orange">User</span>;
                      };

                      return (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td className="tw-font-semibold">{role}</td>
                          <td>{item.total ? formatNumber(item.total) : "0"}</td>
                          <td>{moment(item.createdAt).format("ll")}</td>
                          <td className="tw-text-right">
                            <Link to={`/requests/${item.id}`}>
                              <FontAwesomeIcon icon={faList} className="tw-cursor-pointer tw-text-grey tw-mr-4" />
                            </Link>

                            <FontAwesomeIcon
                              onClick={() => this.handleShowUserCreate(item)}
                              icon={faPen} className="tw-cursor-pointer tw-text-grey tw-mr-4" />
                            <FontAwesomeIcon
                              onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) this.handleDeleteUser(item.id) } }
                              icon={faTrash} className="tw-cursor-pointer tw-text-grey" />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination page={this.state.filters.page} perPage={limit} pages={pages} onPageChange={this.handleMovePage}/>
              </div>
            </div>
          </div>
        </Body>
      </Layout>
    );
  }
}

UsersPage.propTypes = {
  users: PropTypes.object.isRequired,
  loadUsersPage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { users } = state;
  return { users };
}

export default connect(mapStateToProps, {
  loadUsersPage,
})(UsersPage);
